<div class="container" *requireRole="'sm'">
  <div class="app-header u_margin-md-top mb-5 flex flex-row space-between">
    <h1 class="app-header-title w-50" id="title">{{ displayName }}</h1>
    <button mat-button class="px-4" (click)="end()">
      <mat-icon svgIcon="exit" aria-hidden="true"></mat-icon>
      <div class="u_nowrap">End Session</div>
    </button>
  </div>
  <div class="room-grid">
    <div class="room-controls">
      <div class="flex flex-col aligner">
        @if (sharing) {
          @if (sharing.memberId === screenSharingService.memberId) {
            @if (paused) {
              <img src="/images/screen-sharing/screen-sharing-paused.png" width="40" height="40" class="mb-5" alt="Paused">
              <strong class="mb-4">Sharing paused</strong>
            }
            @else if (stopped) {
              <img src="/images/screen-sharing/screen-sharing-stopped.png" width="40" height="40" class="mb-5" alt="Stopped">
              <strong class="mb-4">Sharing stopped</strong>
            }
            @else {
              <img src="/images/screen-sharing/screen-sharing-active.png" width="40" height="40" class="mb-5" alt="Sharing">
              <strong class="mb-4">Sharing your screen</strong>
            }
            <p class="mb-4">Your screen is being shared to the display.</p>
          }
          @else {
            <img src="/images/screen-sharing/user-circle-green.png" width="40" height="40" class="mb-5" alt="User Sharing">
            <strong class="mb-4">{{ sharing.memberDisplayName }} is sharing</strong>
            <p class="mb-4">You may invite another participant to share in their place.</p>
          }
        }
        @else {
          <img src="/images/screen-sharing/user-circle-gray.png" width="40" height="40" class="mb-5" alt="No User Sharing">
          <strong class="mb-4">No one is sharing</strong>
          <p class="mb-4">Select a participant to invite to share or share your screen.</p>
        }
        @if (stopped) {
          <button mat-flat-button color="primary" class="btn-block" (click)="start()">Share Now</button>
        }
        @else {
          @if (paused) {
            <button mat-stroked-button color="primary" class="btn-block" (click)="start()">
              <mat-icon svgIcon="play" aria-hidden="true"></mat-icon>
              Resume
            </button>
          }
          @else if (sharing?.memberId === screenSharingService.memberId) {
            <button mat-stroked-button color="primary" class="btn-block" (click)="pause()">
              <mat-icon svgIcon="pause" aria-hidden="true"></mat-icon>
              Pause
            </button>
          }
          <button mat-stroked-button color="primary" class="btn-block" (click)="stop()">
            <mat-icon svgIcon="stop-circle" aria-hidden="true"></mat-icon>
            Stop
          </button>
        }
      </div>
    </div>
    <div class="room-participants">
      <div class="room-header flex flex-row space-between mb-5">
        <strong>Participants</strong>
        <div class="room-count">{{ screenSharingService.participants?.length ?? '' }}</div>
      </div>
      <div class="room-list">
        @for (user of screenSharingService.participants; track user.memberId; let i = $index) {
          <div class="flex flex-row space-between">
            <div div class="flex flex-row">
              <button mat-icon-button class="small-icon-button" (click)="remove(user.memberId)">
                <mat-icon svgIcon="close" class="small-icon"></mat-icon>
              </button>
              <mat-icon svgIcon="user" class="small-icon"></mat-icon>
              {{ user.memberDisplayName }}
              {{ user.memberId === screenSharingService.memberId ? ' (me)' : '' }}
            </div>
            <button
              mat-flat-button
              color="primary"
              [disabled]="sharing?.memberId === user.memberId || screenSharingService.invitedId === user.memberId"
              (click)="share(user.memberId)"
            >
              @if(screenSharingService.invitedId === user.memberId ) {
                <mat-spinner diameter="15" class="white"></mat-spinner>
              } @else {
                {{ i === 0 ? 'Share' : 'Invite' }}
              }
            </button>
          </div>
        }
      </div>
    </div>
  </div>
</div>

import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ScreenSharingService } from '../../services/screen-sharing.service';
import { ModalService } from 'src/app/components/modals/modal.service';
import { Transition, UIRouterGlobals } from '@uirouter/angular';
import { UserStateService } from 'src/app/auth/services/user-state.service';
import { Subscription } from 'rxjs';
import { DisplayService } from 'src/app/displays/services/display.service';

@Component({
  selector: 'moderator-room',
  templateUrl: './moderator-room.component.html',
  styleUrl: './moderator-room.component.scss'
})
export class ModeratorRoomComponent implements OnInit {

  paused = false;
  stopped = true;
  joinedSubscription: Subscription;
  invitedSubscription: Subscription;
  displayName = '';

  get sharing() {
    return this.screenSharingService.participants &&
      this.screenSharingService.participants.find((user) => user.memberId === this.screenSharingService.senderId);
  }

  constructor(
    protected screenSharingService: ScreenSharingService,
    private displayService: DisplayService,
    private modalService: ModalService,
    private transition: Transition,
    private uiRouterGlobals: UIRouterGlobals,
    private userStateService: UserStateService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    const displayId = this.uiRouterGlobals.params.displayId;
    if (displayId) {
      this.screenSharingService.join(displayId, this.userStateService.getUserFullName(), true);
      this.displayService.getDisplay(displayId).then(() => {
        this.displayName = this.displayService.display.name;
      });
    }

    this.joinedSubscription = this.screenSharingService.joinedSubject.subscribe((value) => {
      if (value === 'joined') {
        this.stopped = !this.screenSharingService.mediaStream;
      } else if (value === 'started') {
        this.stopped = false;
      } else if (value === 'stopped') {
        this.stopped = true;
        this.changeDetectorRef.detectChanges();
      } else if (value === 'removed') {
        this.modalService.confirm('Session terminated',
          'Another moderator has removed you from the room.',
          'OK', 'Cancel'
        ).finally(() => {
          this.redirect();
        });
      }
    });
  }

  redirect() {
    this.transition.router.stateService.go('apps.screen-sharing.moderator-join');
  }

  start() {
    this.screenSharingService.start();
    this.paused = false;
    this.stopped = false;
  }

  pause() {
    this.paused = true;
    this.stopped = false;
  }

  stop() {
    if (this.sharing?.memberId === this.screenSharingService.memberId) {
      this.screenSharingService.stop();
      this.stopped = true;
    } else {
      this.screenSharingService.stopSharing();
    }
  }

  share(memberId: string) {
    if (memberId === this.screenSharingService.memberId) {
      this.start();
    } else {
      this.screenSharingService.invite(memberId);
    }
  }

  remove(id: string) {
    this.modalService.confirmDanger('Remove participant?',
      'The participant will be removed from the room\'s list. They can enter the session code to re-enter the room.',
      'Remove Participant', 'Cancel'
    ).then(() => {
      this.screenSharingService.remove(id);
    }).catch(() => {});
  }

  end() {
    this.modalService.confirmDanger('End session for all?',
      'All participants, including the moderator, will be removed from the room and the sharing session will end.',
      'End Session for All', 'Cancel'
    ).then(() => {
      this.screenSharingService.resetChannel().then(() => {
        this.screenSharingService.reset();
        this.redirect();
      });
    }).catch(() => {});
  }
}

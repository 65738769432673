import { Component, OnDestroy, ViewChild, ElementRef, OnInit } from '@angular/core';
import { ScreenSharingService } from '../../services/screen-sharing.service';
import { CodeInputComponent } from 'angular-code-input';
import { Subscription } from 'rxjs';
import { ModalService } from 'src/app/components/modals/modal.service';
import { UserStateService } from 'src/app/auth/services/user-state.service';

@Component({
  selector: 'screen-sharing',
  templateUrl: './screen-sharing.component.html',
  styleUrls: ['./screen-sharing.component.scss']
})
export class ScreenSharingComponent implements OnDestroy, OnInit {
  joinCode = '';
  joinedSubscription: Subscription;
  invitedSubscription: Subscription;
  joined: boolean;
  codeLength = 6;
  codeBlank: boolean;
  nameBlank: boolean;
  paused: boolean;
  stopped: boolean;
  waiting: boolean;

  @ViewChild('codeInput') codeInput: CodeInputComponent;
  @ViewChild('nameInput') nameInput: ElementRef;

  get moderatorName(): string {
    const mods = this.screenSharingService.participants.filter((item) => item.memberType === "moderator");
    let label = "your moderator";
    if (mods.length === 1) {
      return mods[0].memberDisplayName ? `${label}: <strong>${mods[0].memberDisplayName}</strong>` : label;
    } else if (mods.length > 1) {
      label = `one of ${label}s`;
      const names = mods.map((item) => item.memberDisplayName).filter((item) => !!item).map((name) => `<strong>${name}</strong>`);
      return names.length > 0 ? `${label}: ${names.join(', ')}` : label;
    }
  }

  constructor(
    protected screenSharingService: ScreenSharingService,
    private modalService: ModalService,
    protected userStateService: UserStateService
  ) { }

  ngOnInit(): void {
    this._reset();

    this.joinedSubscription = this.screenSharingService.joinedSubject.subscribe((value) => {
      if (value === 'joined') {
        this.stopped = !this.screenSharingService.mediaStream;
        this.waiting = this.stopped && this.screenSharingService.isModerated;
        this.joined = true;
      } else if (value === 'started') {
        this.waiting = false;
        this.stopped = false;
        this.paused = false;
      } else if (value === 'ended') {
        this.joined = false;
        setTimeout(() => {
          this.codeInput.focusOnField(0);
        });
      } else if (value === 'removed') {
        this.modalService.confirm('Session terminated',
          'A moderator has removed you from the room.',
          'OK', 'Cancel'
        ).finally(() => {
          this.joined = false;
        });
      }
    });

    this.invitedSubscription = this.screenSharingService.invitedSubject.subscribe((value) => {
      if (value) {
        this.share();
      } else {
        this.stop();
      }
    });
  }

  _reset() {
    this.stopped = false;
    this.paused = false;
  }

  ngOnDestroy(): void {
    this.screenSharingService.reset();
    this.joinedSubscription.unsubscribe();
    this.invitedSubscription.unsubscribe();
  }

  join() {
    this._reset();

    this.codeBlank = this.joinCode.length < this.codeLength;
    this.nameBlank = !this.nameInput?.nativeElement.value;
    if (this.codeBlank) {
      this.codeInput.focusOnField(this.joinCode.length);
    } else if (this.nameBlank) {
      this.nameInput.nativeElement.focus();
    } else {
      this.screenSharingService.join(this.joinCode, this.nameInput.nativeElement.value);
    }
  }

  codeChanged(code: string) {
    this.joinCode = code.toUpperCase();
  }

  codeCompleted(code: string) {
    this.codeBlank = false;
    if (!this.screenSharingService.channelError) {
      this.nameInput.nativeElement.focus();
    }
  }

  start() {
    this.paused = false;
    this.stopped = false;

    this.screenSharingService.start();
  }

  pause() {
    this.paused = true;
    this.stopped = false;

    this.screenSharingService.pause();
  }

  resume() {
    this.paused = false;
    this.stopped = false;

    this.screenSharingService.stream();
  }

  stop() {
    this.stopped = true;
    this.paused = false;
    this.waiting = this.screenSharingService.isModerated;

    this.screenSharingService.stop();
  }

  end() {
    this.modalService.confirmDanger('Exit Session?',
      'You will need to enter the session code again to re-enter this room.',
      'Exit Room', 'Cancel'
    ).then(() => {
      if (this.screenSharingService.isModerated) {
        this.screenSharingService.leave();
      } else {
        this.screenSharingService.reset();
      }
    });
  }

  share() {
    this.modalService.confirm('Share your Screen?',
      'The moderator has invited you to share your screen. Please click "Share Now" to start sharing.',
      'Share Now', 'Cancel'
    ).then(() => {
      this.screenSharingService.start();
    }).catch(() => {
      this.screenSharingService.stop();
    });
  }
}
